<template>
  <div class="home">
    <section class="content">
      <figure>
        <img
          alt="engagement portrait of Hosana and Eric"
          src="../assets/home--hero.jpg"
        />
        <figcaption class="caption">
          <h2 class="weds">
            <span>Hosana Tekie</span>
            <span>&</span>
            <span>Eric Josephat</span>
          </h2>
        </figcaption>
      </figure>
    </section>

    <section>
      <div class="content">
        <div class="info p--25-0">
          <div class="labelitems p--10-0">
            <div class="label">When</div>
            <div class="Items">May 16, 2021 at 4PM EST</div>
          </div>

          <div class="labelitems p--10-0">
            <div class="label">Where</div>
            <div class="Items">
              <router-link to="/watch">Online</router-link>
            </div>
          </div>

          <p>
            While we'd love for all our friends, family, and loved ones to join
            us, we want everyone safe and healthy.<br />So please, join
            <router-link to="/watch">virtually</router-link> and celebrate with
            us!
          </p>
        </div>
      </div>
    </section>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
};
</script>

<style scoped>
figure {
  margin: 0;
  position: relative;
}
figcaption {
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: center;
}

img {
  width: 100%;
}

.weds {
  /* align-items: start; */
  /* display: flex; */
  /* flex-direction: column; */
  font-family: "CaslonG";
  text-transform: lowercase;
    font-size: 3em;
  font-weight: 900;
  margin: 0;
  line-height: 0.9;

}
@media screen and (min-width: 720px) {
  .weds {
    font-size: 10em;
  }
}

.info {
  text-align: center;
}

.labelitems {
  /* align-items: baseline; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.label {
  font-weight: 900;
  min-width: 75px;
}
</style>
